import React from "react";
import Form from "./form";
import "../../app.scss";

export default function App() {
  return (
    <div className="main">
      <img src="/img/logo_OTB_horizontal.jpg" className="logo-center"></img>
      <h1 className="mission-statement">Push Dictionary</h1>
      <Form></Form>
    </div>
  );
}
