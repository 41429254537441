import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";

export const phrases = [
  ['Can we push up (our call) to 8:30?', ['filterid1, filterid23'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Can we push up (our call) to 8:30?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Can we push up (our call) to 8:30?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid233'], 'timestamp'],
  ['Can we push up (our call) to 8:30?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Can we push up (our call) to 8:30?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Can we push up (our call) to 8:30?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Can we push up (our call) to 8:30?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Can we push up (our call) to 8:30?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Mannnnes after lunch) for a chat?', ['filterid1, filterid34'], 'timestamp'],
  ['Can we push up (our call) to 8:30?', ['filterid1, filterid2'], 'timestamp'],
  ['Could you squeeze in (20 minutes after lunch) for a chat?', ['filterid1, filterid2'], 'timestamp'],
  ['Hello (20 minutes after lunch) for a chat?', ['filterid1, filterid45'], 'timestamp'],
  // Add more phrases as needed
];

const itemsPerPage = 10;

export default function Body() {
  const [currentPage, setCurrentPage] = useState(1);
  const [phrasesArray, setPhrasesArray] = useState([]);
  const navigate = useNavigate();



  useEffect(() => {
    const getPhrases = async () => {
      try {
        const phraseApiResponse = await axios.get(`https://app.otbenglishpd.com/filter/phrases/`);
        const phraseArr = phraseApiResponse.data;
        const phrasesArray = phraseArr.map(phrase=> {
          return phrase.phrase // Return empty string if not found
        });
        //console.log(phrasesArray);
        
        setPhrasesArray(phrasesArray);
      } catch (error) {
        console.log(error);
      }
    };

    getPhrases();
  }, []);

  const handlePhraseClick = (phraseId) => {
    navigate(`/phrase/${phraseId}`);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const totalPages = Math.ceil(phrasesArray.length / itemsPerPage);
  const startNumber = (currentPage - 1) * itemsPerPage + 1;
  const visiblePhrases = phrasesArray.slice(startIdx, endIdx);
  //console.log(visiblePhrases);
  return (
    <div>
      {visiblePhrases.map((phrase, index) => (
        <div key={index} style={{ marginTop: '30px', marginLeft: '30px', marginBottom: '20px', color: '#005F8E' }}>
          {/* Use a Link or a button to trigger the navigation */}
          <span onClick={() => handlePhraseClick(startNumber + index)}>
            {startNumber + index}. {phrase} 
          </span>
          <div style={{ display: 'flex', marginTop: '10px' }}>
          </div>
        </div>
      ))}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
        <div style={{ display: 'flex', marginRight: '200px' }}>
          {currentPage > 1 && (
            <button
              onClick={handlePrevClick}
              style={{
                background: 'none',
                color: '#005F8E',
                border: 'none',
                position: 'relative',
                padding: '5px 15px',
                marginRight: '20px',
              }}
            >
              <span
                style={{
                  content: '""',
                  position: 'absolute',
                  width: '0',
                  height: '0',
                  borderStyle: 'solid',
                  borderWidth: '10px 15px 10px 0',
                  borderColor: 'transparent #005F8E transparent transparent',
                  left: '5px',
                  top: '5px',
                }}
              ></span>
              <span
                style={{
                  position: 'absolute',
                  background: '#005F8E',
                  width: '20px',
                  height: '10px',
                  left: '15px',
                  top: '10px',
                }}
              ></span>
            </button>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {currentPage < totalPages && (
            <button
              onClick={handleNextClick}
              style={{
                background: 'none',
                color: '#005F8E',
                border: 'none',
                position: 'relative',
                padding: '5px 15px',
              }}
            >
              <span
                style={{
                  content: '""',
                  position: 'absolute',
                  width: '0',
                  height: '0',
                  borderStyle: 'solid',
                  borderWidth: '10px 0 10px 15px',
                  borderColor: 'transparent transparent transparent #005F8E',
                  right: '5px',
                  top: '5px',
                }}
              ></span>
              <span
                style={{
                  position: 'absolute',
                  background: '#005F8E',
                  width: '20px',
                  height: '10px',
                  right: '15px',
                  top: '10px',
                }}
              ></span>
            </button>
          )}
        </div>
      </div>
    </div>
  );

}