import Navbar from '../navbar/app';
import UnitLevel from './unit_level';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { CardHeader, Avatar,Container,Box,BottomNavigation,
  BottomNavigationAction,Paper,FormControl,Grid,TextField,
  Stack,ImageListItem, Button,Typography} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { isExpired, decodeToken } from "react-jwt";
const unit1 = {
    title:"Associate I",
    order:"first",
    description:"basics of workplace English",
    popular_units:["Scheduling","Introductions"]
}

const unit2 = {
    title:"Associate II",
    order:"second",
    description:"advanced of workplace English",
    popular_units:["Scheduling","Introductions"]
}

const unit3 = {
    title:"Manager I",
    order:"third",
    description:"experts of workplace English",
    popular_units:["Scheduling","Introductions"]
}

export default function App(){


    return(
        <Box sx={{width:"100vw",display:"flex",height:"100%"}}>
            <Stack sx={{overflow:"scroll"}}>
                <Box sx={{bgcolor:"#F6F7F9",width:"100vw",alignContent:"center"}}>
                    <Typography variant="h5" sx={{bgcolor:"#F6F7F9",width:"100vw",alignContent:"center",padding:"7%"}}>
                        Unit
                    </Typography>
                </Box>
                <Link to="/unit/Associate1"><UnitLevel unit={unit1}/></Link>
                <Link to="/unit/Associate2"><UnitLevel unit={unit2}/></Link>
                <Link to="/unit/Manager1"><UnitLevel unit={unit3}/></Link>
            
            </Stack>
            <Navbar/>
        </Box>
    )
}