import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sortType: ["timestamp"], //can be alpha, timestamp, or null
  updateFilters: false,
};

const appReducer = createSlice({
  name:"auth",
  initialState:initialState,
  reducers:{
    setSortType(state,action){
      
      state.sortType = action.payload;
    },
    setUpdateFilters(state,action){
      state.updateFilters = action.payload;
    },
    setCurrentUpdatePhrase(state,action){
      state.updatePhrase = action.payload;
    }
  }
})


export const {
  setSortType,
  setUpdateFilters,
  setCurrentUpdatePhrase
} = appReducer.actions;

export default appReducer.reducer;

