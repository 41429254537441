import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";

import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

function Body(props) {
  const { filterid } = useParams();
  const navigate = useNavigate();
  const [filter, setFilter ] = useState("");
  const [phraseArray, setPhraseArray] = useState([]);

    useEffect(() => {
      //console.log(filterid);
      const getFilters = async () => {
        try {
          const filtersApiResponse = await axios.get(`https://app.otbenglishpd.com/filter/filters/`+filterid+"/retrieve_phrases/");
          const phrasesData = filtersApiResponse.data.phrases;
          const filterData = filtersApiResponse.data.filter; 
          setPhraseArray(phrasesData);
          
          setFilter(filterData.name);
        } catch (error) {
          console.log(error);
        }
      };
  
      getFilters();
    },[]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          //onClick={() => add the phrase}
          style={{
            marginBottom: "10px",
            marginTop: "20px",
            padding: "2px",
            fontSize: "20px",
            border: "1px solid grey", // Adjust the border thickness as needed
            textTransform: "none", // Keeps text case-sensitive
            width: "75%",
            //fontWeight: "550", // Make the text lines thicker
            borderRadius: "10px",
            color: "#353535",
          }}
          onClick={()=>navigate(`/createphrase/add`, {state: {filterId: filterid}})}
        >
          +Add Phrase
        </Button>
      </div>
      <div
        style={{
          marginLeft: "55px",
        }}
      >
        {phraseArray.map((phrase, index) => (
          <div
            key={index}
            style={{
              cursor: "pointer",
              marginBottom: "25px", // Add margin-bottom for gap between elements
              marginTop: "12px",
              color: "#005f8f", // Set text color to blue
              marginLeft: "",
              fontWeight: "575",
            }}
            onClick={() => {
              // Perform navigation or any action when clicked
              navigate("/phrase/"+phrase.phrase_id);  // Navigate to a specific path with the filter value
            }}
          >
            {phrase.phrase}
          </div>
        ))}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    components: state.reducer.components,
  };
};


export default connect(mapStateToProps)(Body);
