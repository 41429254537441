import React, { useState, useEffect } from "react";
import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  Icon,
  Button,
  Modal,
  Box,
  Stack,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { connect } from "react-redux";
import { setSortType, setUpdateFilters } from "../../redux/appReducer";
import { decodeToken } from "react-jwt";
import { useSelector } from "react-redux";
import axios from "axios";

function Header(props) {
  const [selectedSort, setSelectedSort] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [updateVisibleFilters, setUpdateVisibleFilters] = React.useState(false);
  //Header User Name


  const tokens = useSelector((state) => state.auth.tokens);
  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  //console.log('tokens', tokens)

  useEffect(() => {
    const credentialData = decodeToken(tokens.access);
    setId(credentialData?.user_id);
    setName(credentialData?.username);
  },[tokens]);

  const [newFilterName, setNewFilterName] = useState("");

  const handleSelectChange = (event) => {
    setSelectedSort(event.target.value);
    if (event.target.value == 1) {
      //timestamp
      props.setSortType("timestamp");
    } else if (event.target.value == 2) {
      //alphabet sort
      props.setSortType("alpha");
    }
  };

  const handleClose = () => {
    setOpen(false);
    props.setUpdateFilters(!props.updateFilters);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleAddFilter = async () => {
    //TODO
    await postData();
    handleClose();
  };

  async function postData() {
    try {
      const response = await axios.post(
        "https://app.otbenglishpd.com/filter/filters/",
        {
          name: newFilterName,
          phrases: [],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  }

  const customStyles = {
    width: "120px", // Adjust the width as needed
  };
  const customSelectStyles = {
    fontSize: "12px", // Adjust the font size for the select input
  };
  return (
    <header className="header-general">
      <div className="filters-header-container">
        <div className="container-space-between">
          <Typography variant="h5">{name}'s Filters</Typography>
          <Button onClick={handleOpen}>
            <AddIcon />
          </Button>
        </div>
        <Typography variant="subtitle1">Sort By</Typography>
        <FormControl fullWidth={false} style={customStyles}>
          <Select
            style={customSelectStyles}
            className="filters-select"
            inputProps={{
              name: "age",
            }}
            value={selectedSort}
            onChange={handleSelectChange}
          >
            <MenuItem value={1}>Most Recent</MenuItem>
            <MenuItem value={2}>Alphabetical</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Stack spacing={3}>
            <a>Create New Filter</a>
            <TextField
              id="standard-password-input"
              label="Filter Name"
              value={newFilterName}
              onChange={(e) => setNewFilterName(e.target.value)}
            />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button variant="contained" onClick={handleAddFilter}>
                Submit
              </Button>
            </div>
          </Stack>
        </Box>
      </Modal>
    </header>
  );
}
const mapStateToProps = (state) => {
  return {
    sortType: state.reducer.sortType,
    updateFilters: state.reducer.updateFilters,
  };
};

const mapDispatchToProps = {
  setSortType,
  setUpdateFilters,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
