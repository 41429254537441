import { Link } from 'react-router-dom';
import React from 'react';
import { CardHeader, Avatar,Container,Box,BottomNavigation,
  BottomNavigationAction,Paper,FormControl,Grid,TextField,
  Stack,ImageListItem, Button,Typography} from "@mui/material";
export default function Header() {
  return (
    <Container className='phrase-header'>
      <h1>Recent Phrases
      <Link to="/createphrase/add" className="add-phrase-link">+</Link>
      </h1>
    </Container>
  );
}

