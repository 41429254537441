import React, { useEffect, useState } from 'react';
import { phrases } from '../body';
import { useParams } from 'react-router-dom';
import axios from "axios";
import { useDispatch } from "react-redux";
import { setCurrentUpdatePhrase } from "../../../redux/appReducer";
import { useSelector } from "react-redux";
    
import { CardHeader, Stack,
  Avatar,Container,Box,Typography,Button,Modal,
  BottomNavigation,BottomNavigationAction,TextField,
  Paper, List,ListItemText,
  ListItem,ListItemAvatar,} from "@mui/material";


export default function Body() {
    const { phraseid } = useParams();
    const [phrase, setPhrase] = useState(undefined);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState([]);

    useEffect(() => {
      let phrase
      const findPhrase = async () => {
        try {
          const phraseApiResponse = await axios.get("https://app.otbenglishpd.com/filter/phrases/"+phraseid+"/");
          //console.log(phraseApiResponse);
          phrase = phraseApiResponse.data
          setPhrase(phraseApiResponse.data);
          dispatch(setCurrentUpdatePhrase(phraseApiResponse.data))

          //console.log(phraseApiResponse.data);
        } catch (error) {
          console.log(error);
        }
      };
      const getFilters = async () => {
        try {
          await findPhrase()
          const filtersApiResponse = await axios.get(`https://app.otbenglishpd.com/filter/filters/`)
          const allFilters = filtersApiResponse.data;
          let noNullFilter = phrase.filters.filter(filter => filter !== 1)
          const newFilters = noNullFilter.map((filter)=>{
            let temp = allFilters.find((f) => f.id === filter)
            return temp.name
          })
          setFilters(newFilters.join(", "));
        } catch (error) {
          console.log(error);
        }
      };

      getFilters()



    },[])
   
  return (
    <Container>
      <Typography variant="h4">
        Definitions:
      </Typography>
      <Typography variant="h6">
        { phrase?phrase.definition:"" }
      </Typography>
      <Typography variant="h4">
        Student Notes:
      </Typography>
      <Typography variant="h6">
        { phrase?phrase.student_notes:"" }
      </Typography>
      <Typography variant="h4">
        Filters:
      </Typography>
      <Typography variant="h6">
        { phrase?filters:""}
      </Typography>
      {/* <section>
        <h2>Filters:</h2>
        <p>{ filter?filter.name:"" }</p>
      </section> */}

      {/* <section>
        <h2>Correct Uses:</h2>
        <p>{ correctUses }</p>
      </section>

      <section>
        <h2>Incorrect Uses:</h2>
        <p>{ incorrectUses }</p>
      </section> */}
    </Container>
  );
}