import React, { useState, useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";
import ModeIcon from "@mui/icons-material/Mode";
import {
  TextField,
  Modal,
  Button,
  Stack,
  Paper,
  Box,
  styled,
} from "@mui/material";
import { useSelector } from "react-redux";
import { isExpired, decodeToken } from "react-jwt";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setTokens } from "../../redux/authSlice";

export default function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tokens = useSelector((state) => state.auth.tokens);

  const credentialData = decodeToken(tokens.access);

  const [credentials, setCredentials] = useState({
    username: credentialData.username,
    password: credentialData.password,
    email: credentialData.email,
  });

  const [unencryptedPassword, setUnencryptedPassword] = useState("");

  const [changedFieldValue, setChangedFieldValue] = useState("");
  const [changedConfirmFieldValue, setchangedConfirmFieldValue] = useState("");

  // Initialize the password state
  const [passwordHidden, setPasswordHidden] = useState(credentials.password);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [editField, setEditField] = useState("");
  const [inputPassword, setInputPassword] = useState("");

  const editFieldVariable = (value) => {
    handleOpen();
    setEditField(value);
  };

  const [open1, setOpen1] = React.useState(false);

  const [open2, setOpen2] = React.useState(false);

  const handlePasswordChangeClose = async (fieldName) => {
    setOpen2(false);
    if (changedFieldValue == changedConfirmFieldValue) {
      await putData(credentials, inputPassword, changedFieldValue);
      //await updateTokens();
    }
  };

  const handleOpen1 = async () => {
    let updatedCredentials = { ...credentials };
    //call here
    const success = await putData(
      updatedCredentials,
      inputPassword,
      inputPassword
    );

    if (success && editField == "password") {
      setOpen2(true);
    } else if (success) {
      setOpen1(true);
    }

    setOpen(false);
  };
  const handleClose1 = async (fieldName) => {
    setOpen1(false);
    let updatedCredentials = { ...credentials };

    if (fieldName === "username") {
      updatedCredentials.username = changedFieldValue;
    } else if (fieldName === "email") {
      updatedCredentials.email = changedFieldValue;
    }

    try {
      await putData(
        updatedCredentials,
        unencryptedPassword,
        unencryptedPassword
      );

      // This will only execute after putData completes
      //await updateTokens(); // This updates tokens
    } catch (error) {
      console.error("Error:", error);
      // Handle errors if putData or updateTokens fail
    }
  };

  async function putData(
    updatedCredentials,
    checkPassword = "",
    newPassword = ""
  ) {
    let updatedSuccess = false;

    try {
      const response = await axios.put(
        `https://app.otbenglishpd.com/users/${credentialData.user_id}/`,
        {
          username: updatedCredentials.username,
          email: updatedCredentials.email,
          password: credentialData.password,
          unencrypted_password: checkPassword,
          new_password: newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status < 400) {
        updatedSuccess = true;
        setCredentials({
          username: response.data["username"],
          password: response.data["password"],
          email: response.data["email"],
        });
        setUnencryptedPassword(newPassword);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (updatedSuccess && newPassword != "") {
      updateTokens(newPassword, updatedCredentials.username);
      return true;
    }

    return false;
  }

  async function updateTokens(newPassword, newUsername) {
    try {
      const response = await axios.post(
        "https://app.otbenglishpd.com/users/login",
        {
          username: newUsername,
          password: newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response data here
      if (response.status < 400) {
        dispatch(setTokens(response.data));
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1000);
        });
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    // After the component is mounted, change the password content
    setPasswordHidden("*".repeat(0));
  }, []);
  useEffect(() => {
    setPasswordHidden("*".repeat(0));
  }, [credentials.password]);
  const logout = () => {
    localStorage.setItem("tokens","");
    dispatch(setTokens({"access":"","refresh":""}));
    navigate("/");
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "start",
    color: theme.palette.text.secondary,
    border: "1px solid #BDC9D2", // Add a black border to each Item
    borderRadius: "4px",
  }));

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const itemContentStyle = {
    display: "flex",
    alignItems: "center",
  };

  const labelStyle = {
    flex: ".5",
  };

  const testStyle = {
    flex: "0.97",
    fontSize: ".75rem",
    fontFamily: "Arial, sans-serif",
  };

  const logOutStyle = {
    flex: "0.5",
    fontSize: "1rem",
    fontFamily: "Arial, sans-serif",
  };

  const logoutButtonStyle = {
    textTransform: "none", // Preserve case sensitivity
    border: "1px solid #000", // Add a black border to the button
    borderRadius: "4px", // Add rounded corners to the button
    color: "black", // Set the text color to black
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <Box sx={{ width: "90%" }}>
        <Stack spacing={2}>
          <Item>
            <div style={itemContentStyle}>
              <div style={labelStyle}>Username</div>
              <div style={testStyle}>
                <a style={{ color: "#002639" }}>{credentials.username}</a>
              </div>
              <div onClick={() => editFieldVariable("username")}>
                <ModeIcon></ModeIcon>
              </div>
            </div>
          </Item>
          <Item>
            <div style={itemContentStyle}>
              <div style={labelStyle}>Email</div>
              <div style={testStyle}>
                <a style={{ color: "#002639" }}>{credentials.email}</a>
              </div>
              <div onClick={() => editFieldVariable("email")}>
                <ModeIcon></ModeIcon>
              </div>
            </div>
          </Item>
          <Item>
            <div style={itemContentStyle}>
              <div style={labelStyle}>Password</div>
              <div style={testStyle}>
                <a style={{ color: "#002639" }}>{passwordHidden}</a>
              </div>
              <div onClick={() => editFieldVariable("password")}>
                <ModeIcon></ModeIcon>
              </div>
            </div>
          </Item>
          <Button variant="outlined" style={logoutButtonStyle} onClick={logout}>
            <div style={logOutStyle}>
              <a style={{ color: "#002639" }}>Log Out</a>{" "}
            </div>
          </Button>
        </Stack>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Stack spacing={2}>
            <a style={logOutStyle}>Enter Password</a>
            <TextField
              id="standard-password-input"
              label="Password"
              type="password"
              value={inputPassword}
              onChange={(e) => setInputPassword(e.target.value)}
            />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button variant="contained" onClick={handleOpen1}>
                Submit
              </Button>
            </div>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Stack spacing={2}>
            <a style={logOutStyle}>New {editField}</a>
            <TextField
              id="standard-password-input"
              label={`Enter new ${editField}`}
              value={changedFieldValue}
              onChange={(e) => setChangedFieldValue(e.target.value)}
            />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={() => setOpen1(false)} variant="outlined">
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => handleClose1(editField)}
              >
                Submit
              </Button>
            </div>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={open2}
        onClose={handlePasswordChangeClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Stack spacing={2}>
            <a style={logOutStyle}>New {editField}</a>
            <TextField
              id="standard-password-input"
              label={`Enter new ${editField}`}
              value={changedFieldValue}
              onChange={(e) => setChangedFieldValue(e.target.value)}
            />

            <a style={logOutStyle}>Confirm {editField}</a>
            <TextField
              id="standard-password-input"
              label={`Confirm new ${editField}`}
              value={changedConfirmFieldValue}
              onChange={(e) => setchangedConfirmFieldValue(e.target.value)}
            />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handlePasswordChangeClose} variant="outlined">
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => handlePasswordChangeClose(editField)}
              >
                Submit
              </Button>
            </div>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
