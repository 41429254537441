import Navbar from '../navbar/app';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { CardHeader, Avatar,Container,Box,BottomNavigation,
  BottomNavigationAction,Paper,FormControl,Grid,TextField,
  Stack,ImageListItem, Button,Typography} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { isExpired, decodeToken } from "react-jwt";

export default function UnitLevel({unit}){
    const title=unit.title, order=unit.order, description=unit.description, popular_units=unit.popular_units;

    return(
        <Box sx={{ display: "flex",
            border: "2px solid #bdc9d2",
            padding: "0.3em 1em 0.1em 1em",
            borderRadius: "0.3em",
            margin: "20px",height:"70%",maxHeight:"70%"}} className="one-unit-level">
            <div className="unit_title">{title}</div>
            The {order} level of the course, in {title} you will learn {description}.
            
            <p>Popular Units:
            <ul>
                {popular_units.map((sub_unit)=>(
                    <li>{sub_unit}</li>
                ))}
            </ul>
            </p>
        </Box>
    )
}