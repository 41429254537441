

import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    tokens: localStorage.getItem("tokens")? JSON.parse(localStorage.getItem("tokens")):{"access":"","refresh":""},
    user:undefined
  };
  
const authReducer = createSlice({
    name:"auth",
    initialState:initialState,
    reducers:{
      setTokens(state,action){
        //console.log('state.tokens', state.tokens)
        state.tokens = action.payload;
      },
      setUser(state,action){
        state.user = action.payload;
      }
    }
})
  
export const {
    setTokens,
    setUser
  } = authReducer.actions;
  
export default authReducer.reducer;
  
  