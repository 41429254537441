import React, { useState, useEffect } from "react";
import { CardHeader, Avatar,Container,Box,BottomNavigation,
  BottomNavigationAction,Paper,FormControl,Grid,TextField,Stack,ImageListItem, Button,Typography} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { isExpired, decodeToken } from "react-jwt";

export default function App(props) {
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  const [id, setId] = useState(null);
  const tokens = useSelector((state) => state.auth.tokens);

  const credentialData = decodeToken(tokens.access);


  const [name, setName] = useState(credentialData.username);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    props.setSearch(searchQuery)
  };

  useEffect(() => {
    setId(decodeToken(tokens.access)?.user_id);
  },[]);


  return (
    <Container >
        <Box direction="row" spacing={2} style={{}} display={"flex"}>
                <Link to="/profile" style={{width:"20%",position:"relative",paddingTop:"7%"}}>
                      <Avatar>T</Avatar>
                </Link>
                <Typography variant="h6"style={{width:"30%",position:"relative",paddingTop:"7%"}}>
                      {`Welcome ${name.charAt(0).toUpperCase() + name.slice(1)}!`}
                </Typography>
                <img style={{position:"relative"}}
                      src="/img/logo_OTB_horizontal.png"
                      className="logo_home"
                      alt="OTB Logo"
                />
              
          
        </Box>

        <FormControl  >
          <Box style={{display:"flex"}}>
              <TextField style={{width:"100%"}}
                id="input search" 
                label="Filters, Phrases, Units.." 
                variant="standard" 
                value={searchQuery}
                onChange={handleSearchChange}/>
              <Button style={{alignSelf:"end",marginLeft:"64px"}}
                onClick={handleSearchSubmit}
                variant="outlined"
              >
                search
              </Button>

              
            
            
          </Box>
        </FormControl>
        
          
    </Container>
  );

}