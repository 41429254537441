import Header from "./header";
import Body from "./body";
import Navbar from "../navbar/app";
import { Provider } from "react-redux";
import appStore from "../../redux/appStore";

export default function App() {
  return (
    <Provider store={appStore}>
      <div>
        <Header />
        <Body />
        <Navbar />
      </div>
    </Provider>
  );
}
