import Header from './header';
import Body from './body';
import Navbar from '../navbar/app';
import { useSelector } from "react-redux";
import { decodeToken } from "react-jwt";

import React, { useState, useEffect} from "react";
import {Button, TextField, Box, Stack,Grid, Typography, List,ListItemButton,ListItemText,OutlinedInput,
  Collapse,ListSubheader,Checkbox,ListItem,InputLabel,MenuItem,FormHelperText,FormControl,Select,SelectChangeEvent,
  Divider
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { redirect, useNavigate,useParams, useLocation } from "react-router-dom";
import axios from "axios";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
export default function App() {
  const { update } = useParams();
  const location = useLocation();

  const { filterId } = location.state || {};
  

  const updatePhrase = useSelector((state) => state.reducer.updatePhrase);
  let startphrase = ""
  let startDefinition = ""
  let startNotes = ""
  let startCheckedFilters = []

  if(update&&updatePhrase==="update"){
    startphrase = (updatePhrase.phrase)
    startDefinition = (updatePhrase.definition)
    startNotes = (updatePhrase.student_notes)
    startCheckedFilters = ([updatePhrase.filter_details.name])
  }
  const [phrase, setPhrase] = useState(startphrase);
  const [definition, setDefinition] = useState(startDefinition);

  const [notes, setNotes] = useState(startNotes);

  const [addableFilters, setAddableFiltersList] = useState([]);
  const [checkedFilters, setCheckedFilters] = useState(startCheckedFilters);
  const [checkedFiltersDetailed, setCheckedFiltersDetailed] = useState([]);


  const [phraseValidation, setPhraseValidation] = useState(false);
  const [definitionValidation, setDefinitionValidation] = useState(false);

  const [notesValidation, setNotesValidation] = useState(false);

  const [id, setId] = useState(null);
  const tokens = useSelector((state) => state.auth.tokens);
  const navigate = useNavigate()

  useEffect(() => {
    setId(decodeToken(tokens.access).user_id);

  }, []);
  useEffect(() => {
    const getFilters = async () => {
      try {
        const filtersApiResponse = await axios.get(`https://app.otbenglishpd.com/filter/filters/`);
        let filtersArray = filtersApiResponse.data;
        
        // console.log(filtersApiResponse);
        // console.log(filtersApiResponse.length);
        // Build filterArray using filtersApiResponse and phraseApiResponse
        filtersArray = filtersArray.filter(filter => filter.id > 1)
        const newFilterArray = filtersArray.map(filter => {
          const filterName = filter.name;
          const filterId = filter.id;
          return {filterName:filterName, filterId:filterId};
        });

        //set starting filter if there is one
        if(filterId){
          setCheckedFilters([...checkedFilters, newFilterArray.filter(element=> element.filterId == filterId)[0].filterName])
        }
        
          setAddableFiltersList(newFilterArray);
      } catch (error) {
        console.log(error);
      }
    };

    getFilters();
  }, []);

  const onClickDone = async ()=> {

    try {
      let phraseApiResponse;
      phraseApiResponse = await axios.post(
        "https://app.otbenglishpd.com/filter/phrases/",
        {
          filters:checkedFiltersDetailed.map((filter)=>{return filter.filterId}),
          phrase:phrase,
          definition:definition,
          student_notes:notes,
          user: id
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      //console.log(phraseApiResponse);
      setPhrase("");
      setDefinition("");

      setNotes("");
      navigate("/phrase/"+phraseApiResponse.data.phrase_id);

    } catch (error) {
      console.log(error);
    }
    
  };

  const checkFilterBox= (event)=> {
    const value= event.target.value
    setCheckedFilters(
      typeof value === 'string' ? value.split(',') : value,
    );
    setCheckedFiltersDetailed(
      typeof value === 'string' ? value.split(',') : value.map((checkedFilter) => 
      {
        return addableFilters.find((filter)=> {return filter.filterName===checkedFilter})
      }),
    );


  };
  const displayAddableFiltersList = () => {
    // console.log(addableFilters);
    return addableFilters.map((filter)=>
      (<MenuItem disableGutters={true} key={filter.filterName} value={filter.filterName}>
        <Checkbox checked={checkedFilters.indexOf(filter.filterName) !== -1}/>
        <ListItemText primary={filter.filterName}/>
      </MenuItem>)
    )
  };


  const displayFiltersList = () => {

    return checkedFilters.map((filter,idx)=>{
       
      const handleCloseFilter = (i) => {
        setCheckedFilters([...checkedFilters.slice(0,i),...checkedFilters.slice(i+1)])
      };
      const FilterDisplay= 
      (<Grid container key={idx} direction="row" style={{width:"auto",alignContent:"center", alignItems:"center",padding:"1%"}} >
            <Grid item fontSize='14px'>
              {filter}
            </Grid>
            <Grid item style={{}}>
            <Button style={{padding:"0px",width:"14px",maxWidth:"14px",minWidth:"14px"}} onClick={()=>{handleCloseFilter(idx)}}>
              <CloseOutlinedIcon fontSize='14px'/>
            </Button>
              
          </Grid>
        </Grid>)
      return FilterDisplay;
      

      

    })
  };



  return (
    <Box style={{width: '100%', height: '100%', background: 'white'}}>
      <Stack style={{width: '100%', height: '100%', backgroundColor:"#FFFFFF",
          alignContent:"center", alignItems:"baseline",justifyContent:"center"}} direction="column">
        <Stack 
        style={{width: '100%', height: '5vh', background: '#F6F7F9', 
        alignContent:"center", alignItems:"center",justifyContent:"center",  position: "sticky", top: 0, zIndex: 1000 }} 
        
        direction="row"
      >
          <Button onClick={()=>{navigate(-1)}} style={{color: '#FFFFFF',backgroundColor:'#005F8E', fontSize: 12, fontFamily: 'Inter', width: '10%', 
          height: '20%', marginTop:"1%",marginBottom:"1%",marginLeft:"2%",marginRight:"10%",padding:"2.5%",
          wordWrap: 'break-word'}}>
              cancel
          </Button>
          <Typography variant="h1" component="h2" 
            style={{color: '#353535', fontSize: 15, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}
          >
              Create New Phrase
          </Typography>
          <Button onClick={onClickDone} style={{color: '#FFFFFF',backgroundColor:'#005F8E', fontSize: 12, fontFamily: 'Inter', width: '10%', 
          height: '20%', marginTop:"1%",marginBottom:"1%",marginLeft:"10%",marginRight:"10%",padding:"2.5%",
          wordWrap: 'break-word'}}>
              Done
          </Button>
        </Stack>
        <Divider style={{width: '100%', height: '100%'}}/>
        <Box style={{width: '90%', height: '100%', paddingLeft:"5%"}}>
          <Typography variant="h1" component="h2" 
          style={{color: '#353535', fontSize: 24, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word',
          paddingBottom:"2%",paddingTop:"2.5%"}}>
            Phrase
          </Typography>
          <TextField
            style={{width: '90%', height: '100%', borderRadius:"1%",paddingBottom:"5%"}}
            id="outlined-multiline-static"
            multiline
            rows={3}
            size="small"
            defaultValue=""
            value={phrase}
            onChange={(event) => {
              setPhrase(event.target.value);
              setPhraseValidation(event.target.value.length>256)
            }}
            helperText={phraseValidation?"Phrase length exceeds limitation"+phrase.length+"/256":""}

          />
        </Box>
        <Box style={{width: '90%', height: '100%', paddingLeft:"5%"}}>
          <Typography variant="h1" component="h2" 
          style={{color: '#353535', fontSize: 24, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word',
          paddingBottom:"2%"}}>
            Definition
          </Typography>
          <TextField
            style={{width: '90%', height: '100%', borderRadius:"1%",paddingBottom:"5%"}}
            id="outlined-multiline-static"
            multiline
            rows={3}
            size="small"
            defaultValue=""
            value={definition}
            onChange={(event) => {
              setDefinition(event.target.value);
              setDefinitionValidation(event.target.value.length>256)
            }}
            helperText={definitionValidation?"Phrase length exceeds limitation"+definition.length+"/256":""}

          />
        </Box>

        <Typography variant="h1" component="h2" 
          style={{color: '#353535', fontSize: 24, fontFamily: 'Inter', fontWeight: '500', 
          wordWrap: 'break-word', paddingLeft:"5%",paddingBottom:"5%"}}
        >
          Filters
        </Typography>
        <Grid container 
          style={{width: '90%',paddingLeft:"5%",paddingBottom:"2.5%",paddingTop:"2.5%",paddingBottom:"10%"}}
          spacing={2}>
          {displayFiltersList()}
        </Grid>
        <FormControl style={{width: '80%',paddingLeft:"5%",paddingBottom:"5%"}}>
        <InputLabel style={{width: '90%',paddingLeft:"5%",paddingBottom:"5%"}} id="demo-multiple-checkbox-label">Select Filter</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={checkedFilters}
          onChange={checkFilterBox}
          input={<OutlinedInput label="Select Filter" />}
          renderValue={(checked) => checked.map((filter)=>filter).join(', ')}
        >
          {displayAddableFiltersList()}
        </Select>
      </FormControl>
      <Box onClick={() => navigate("/filter")} style={{width: '90%', height: '100%', paddingLeft:"5%"}}>
        <Button variant="outlined" style={{color: '#005F8E', fontSize: 20, width: '90%',position:"relative",
        fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word',borderRadius:0}}>
          Create new filter
        </Button>
        </Box>
      

        

        <Box style={{width: '90%', height: '100%', paddingLeft:"5%",paddingBottom:"12.5%",paddingTop:"20%"}}>
          <Typography variant="h1" component="h2" 
          style={{color: '#353535', fontSize: 24, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word',
          paddingBottom:"2%"}}>
            Notes
          </Typography>
          <TextField
            style={{width: '90%', height: '100%', borderRadius:"1%"}}
            id="outlined-multiline-static"
            multiline
            error={notesValidation}
            rows={3}
            size="small"
            defaultValue=""
            value={notes}
            onChange={(event) => {
              setNotes(event.target.value);
              setNotesValidation(event.target.value.length>256)

            }}
            helperText={notesValidation?"Phrase length exceeds limitation"+notes.length+"/256":""}
          />
        </Box>

        
        <Navbar/>

      </Stack>
    </Box>

  );
}
