import * as React from 'react';
import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Add, Remove} from '@mui/icons-material';

import Header from './header';
import Navbar from '../../navbar/app';
import associate1 from './associate1.json';
import associate2 from './associate2.json';
import manager1 from './manager1.json';

const content1 = ['Greeting and Introduction','Assistance','Emailing','Scheduling','Making Calls','Ordering+Recommending','Describing Products + Services']

export default function SimpleAccordion({level}) {
    const [openStatus, setOpenStatus] = useState(
        [0,0,0,0,0,0,0]
    )
    const [content, setContent] = useState([]);
    const [jsonContent, setJsonContnet] = useState(associate1);

    useEffect(() => {
        if(level=='Associate 1'){
            setJsonContnet(associate1);
        }
        else if(level=='Associate 2'){
            setJsonContnet(associate2);
        }
        else if(level=='Manager 1'){
            setJsonContnet(manager1);
        }
        //console.log('level==',level)
        //console.log(jsonContent);
    },[]);

    const toggleAccordion = (i) =>{
        let newarr = [...openStatus];
        if(openStatus[i]==0){
            newarr[i]=1;
            setOpenStatus(newarr)
        }
        else{
            newarr[i]=0;
            setOpenStatus(newarr)
        }
    }
    
  return (
    <div>
        <Header level={level}/>
        {Object.entries(jsonContent).map(([key, val], i)=>(
            <Accordion onClick={()=>toggleAccordion(i)}>
            <AccordionSummary expandIcon={ openStatus[i] == 0? <Add/>: <Remove/>}>
                {Object.keys(val).map((unit)=>(<h3>{unit}</h3>))}
            </AccordionSummary>
            {/* <p>This section contains the collapsible content of {key}</p> */}
            <AccordionDetails>
                {Object.values(val).map((sections)=>(
                    <div>
                        {Object.entries(sections).map(([section, phrases])=>(
                            <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            {section}
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul>
                            {phrases.map((phrase)=>(
                                <li>{phrase}</li>
                            ))}
                            </ul>
                        </AccordionDetails>
                        </Accordion>
                        ))}
                    </div>
                ))}
            </AccordionDetails>
        </Accordion>
        ))}
      <Navbar/>
    </div>
  );
}