import React from "react";
import Form from "./form";
import Picture from "./picture";
import Navbar from '../navbar/app';
export default function App() {
  return (
    <div>
      <img src="/img/logo_OTB_horizontal.jpg" className="logo-center"></img>
      <Picture></Picture>
      <Form></Form>
      <Navbar/>
    </div>
  );
}
