import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Card, CardContent, CardHeader, Typography, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

//import { listenToSortTypeChanges } from "./redux/bodyActions";

function Body(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [filterArray2, setFilterArray2] = useState([]);
  const [filteredSortedArray, setFilteredSortedArray] = useState([]);

  useEffect(() => {
    const getFilters = async () => {
      try {
        const filtersApiResponse = await axios.get(`https://app.otbenglishpd.com/filter/filters/`);
        const phraseApiResponse = await axios.get(`https://app.otbenglishpd.com/filter/phrases/`);
        const filtersArray = filtersApiResponse.data;
        
        // console.log(filtersApiResponse);
        // console.log(filtersApiResponse.length);
        // Build filterArray using filtersApiResponse and phraseApiResponse
        const newFilterArray = filtersArray.map(filter => {
          const filterName = filter.name;
          const filterTimeStamp = filter.timestamp;
          const filterPhraseIds = filter.phrases;
          const filterId = filter.id;

          
          const phrasesArray = filterPhraseIds.map(phraseId => {
            const foundPhrase = phraseApiResponse.data.find(phrase => phrase.phrase_id === phraseId);
            return foundPhrase ? foundPhrase.phrase : ''; // Return empty string if not found
          });
          
          return [filterName, phrasesArray, filterTimeStamp,filterId];
        });

        setFilterArray2(newFilterArray);
        setFilteredSortedArray(newFilterArray); // Update filteredSortedArray
      } catch (error) {
        console.log(error);
      }
    };

    getFilters();
    //console.log(props.updateFilters);
  }, [props.updateFilters]);

  useEffect(() => {

    if (props.sortType === "alpha") {
      setFilteredSortedArray(prevArray => {
        const sortedAlphabetArray = prevArray.slice().sort((a, b) => {
          const nameA = a[0].toLowerCase();
          const nameB = b[0].toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
        return sortedAlphabetArray;
      });
    } else if (props.sortType === "timestamp") {
      setFilteredSortedArray(prevArray => {
        const sortedTimestampArray = prevArray.slice().sort((a, b) => {
          const timestampA = new Date(a[2]).getTime();
          const timestampB = new Date(b[2]).getTime();
          return timestampB - timestampA;
        });
        return sortedTimestampArray;
      });
    }
  }, [props.sortType]);

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;

  const totalPages = Math.ceil(filterArray2.length / itemsPerPage);

  //const startNumber = (currentPage - 1) * itemsPerPage + 1;

  const visibleFilters = filteredSortedArray.slice(startIdx, endIdx);
  //console.log(visibleFilters);
  useEffect(() => {
    //dispatch(listenToSortTypeChanges());
  }, [dispatch]);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <div>
      <div style={{ maxWidth: "80%", margin: "0 auto" }}>
        {visibleFilters.map((filter, index) => (
          <div
            key={index}
            onClick={() => navigate("/filter/"+filter[3])}
            style={{ cursor: "pointer" }}
          >
            <Card
              key={index}
              style={{
                marginTop: "5px",
                borderRadius: "10px",
                border: "2px solid #bbc8d1",
                height: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <CardContent style={{ flexGrow: 4 }}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: "500", fontSize: "1.1em" }}
                >
                  {filter[0]==="null"? "No Filter" : `#${filter[0]}`}
                </Typography>
                <Chip
                  label={`${filter[1].length} Phrases`}
                  style={{ backgroundColor: "#d0dee7" }}
                />
                {filter[1][0] && filter[1][0].trim() !== "" && (
                  <div style={{ marginTop: "0px" }}>
                    <a>Example: {filter[1][0]}</a>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "100px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", marginRight: "200px" }}>
          {currentPage !== 1 && (
            <button
              onClick={handlePrevClick}
              style={{
                background: "none",
                color: "#005F8E",
                border: "none",
                position: "relative",
                padding: "5px 15px",
                marginRight: "20px",
              }}
            >
              <span
                style={{
                  content: '""',
                  position: "absolute",
                  width: "0",
                  height: "0",
                  borderStyle: "solid",
                  borderWidth: "10px 15px 10px 0",
                  borderColor: "transparent #005F8E transparent transparent",
                  left: "5px",
                  top: "5px",
                }}
              ></span>
              <span
                style={{
                  position: "absolute",
                  background: "#005F8E",
                  width: "45px",
                  height: "20px",
                  left: "19px",
                  top: "5px",
                }}
              ></span>
            </button>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {currentPage !== totalPages && (
            <button
              onClick={handleNextClick}
              disabled={currentPage === totalPages}
              style={{
                background: "none",
                color: "#005F8E",
                border: "none",
                position: "relative",
                padding: "5px 15px",
              }}
            >
              <span
                style={{
                  content: '""',
                  position: "absolute",
                  width: "0",
                  height: "0",
                  borderStyle: "solid",
                  borderWidth: "10px 0 10px 15px",
                  borderColor: "transparent transparent transparent #005F8E",
                  right: "5px",
                  top: "5px",
                }}
              ></span>
              <span
                style={{
                  position: "absolute",
                  background: "#005F8E",
                  width: "45px",
                  height: "20px",
                  right: "19px",
                  top: "5px",
                }}
              ></span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    components: state.reducer.components,
    sortType: state.reducer.sortType,
    updateFilters: state.reducer.updateFilters,
  };
};

export default connect(mapStateToProps)(Body);
