import React, { useEffect, useState } from "react";
import { TextField, Button, Checkbox } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setTokens } from "../../redux/authSlice";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

import { useSelector } from "react-redux";

export default function App() {
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [usernameEmpty, setUsernameEmpty] = useState(true);
  const [passwordEmpty, setPasswordEmpty] = useState(true);

  const tokens = useSelector((state) => state.auth.tokens);
  //const [authToken, setAuthToken] = useState();

  const [error, setError] = useState("");
  const [remember, setRemember] = useState(false)

  const navigate = useNavigate();

  const usernameRegex = /^(?=.*[a-z])(?=.*\d)[A-Za-z\d]{6,20}$/;
  const passwordRegex = /^[A-Za-z0-9!@#$%^&*()_+]{6,20}$/;
  const emptyRegex = /^$/;

  const theme = useTheme();

  const handleChange = (e, text, empty) => {
    const { name, value } = e.target;

    const regexPatternEmpty = empty;
    const isEmpty = regexPatternEmpty.test(value);

    credentials[e.target.name] = e.target.value;
    setCredentials({ ...credentials, [name]: value });

    if (text == usernameRegex) {
      setUsernameEmpty(isEmpty);
    } else if (text == passwordRegex) {
      setPasswordEmpty(isEmpty);
    }
  };

  const loginHandle = async () => {
    await postData();
    if (usernameEmpty || passwordEmpty) {
      setError("empty");
    } else {
      setError("mismatch");
    }
  };

  const loginErrorHandle = (error) => {
    if (error == "empty") {
      return "Error: Empty Username/Password";
    } else if (error == "mismatch") {
      return "Error: Username and Password don't match";
    }
  };

  const signup = () => {
    navigate("/signup");
    navigate("/signup");
  };

  async function postData() {
    try {
      const response = await axios.post(
        "https://app.otbenglishpd.com/users/login",
        {
          username: credentials.username,
          password: credentials.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Handle the response data here
      if (response.status < 400) {
        const tokens = response.data;
        localStorage.setItem("tokens",JSON.stringify(tokens));
        dispatch(setTokens(response.data));
        navigate("/home");
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  }

  return (
    <div className="container-login">
      <form style={{position: "relative"}}>
        <h2 className="form-title">Login</h2>
        <p>First time? <a onClick={signup} style={{color: "#005E8D", fontSize: 16, fontFamily: 'Poppins', fontWeight: '600', wordWrap: 'break-word'}}>Register Here</a></p>
        <TextField
          error={error}
          name="username"
          label="Username"
          value={credentials.username}
          onChange={(e) => handleChange(e, usernameRegex, emptyRegex)}
          required
          className="block-button"
          InputProps={{
            sx: {
              borderRadius: "15px"
            }
          }}
        />
        <TextField
          error={error}
          name="password"
          label="Password"
          type="password"
          value={credentials.password}
          onChange={(e) => handleChange(e, passwordRegex, emptyRegex)}
          required
          className="block-button"
          InputProps={{
            sx: {
              borderRadius: "15px"
            }
          }}
        />
        <div style={{ color: "red", marginTop: "5px"}}>{loginErrorHandle(error)}</div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "15px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox checked={remember} onChange={() => setRemember(!remember)}></Checkbox>
                <p style={{ marginLeft: "5px" }}>Remember me</p>
            </div>
            <a style={{ textDecoration: "none" }}>Forgot Password</a>
        </div>
          <Button
            variant="contained"
            color={theme.primary}
            className="block-button"
            onClick={loginHandle}
            style={{borderRadius: "100px"}}
          >
            Login
          </Button>
      </form>
    </div>
  );
}
