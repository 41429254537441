import { Link } from 'react-router-dom';
import React from 'react';

import { TextField, Button } from "@mui/material";

export default function Header({level}) {
   let F = level[0].toUpperCase();
   const currLevel = F + level.slice(1);
  return (
    <div className='phrase-header'>
      <Link to="/unit" className="add-phrase-link">
      <Button variant="outlined" className='leftButton'> Back </Button>
      </Link>
      <h1>{currLevel}</h1>
    </div>
  );
}

