import Form from "./form";
import '../../app.scss';

export default function App(){
    return(
        <div className="main">
            <img src="/img/logo_OTB_horizontal.jpg" className="logo-center"></img>
            <Form/>
        </div>
    )
}