import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import appReducer from "./appReducer"; 
import authSlice from "./authSlice"; 


// const saveStateToLocalStorage = (store) => (next) => (action) => {
//   let result = next(action);
//   localStorage.setItem('reduxState', JSON.stringify(store.getState()));
//   return result;
//  };

//  const loadStateFromLocalStorage = () => {
//   try {
//      const serializedState = localStorage.getItem('reduxState');
//      if (serializedState === null) {
//        return undefined;
//      }
//      return JSON.parse(serializedState);
//   } catch (err) {
//      return undefined;
//   }
//  };

const rootReducer = {
  reducer: appReducer, // Here, tokens might be the slice of the state managed by tokenReducer
  auth: authSlice
};

const appStore = configureStore({
  reducer: rootReducer
});

export default appStore;
