import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { decodeToken } from "react-jwt";
import axios from "axios";

export default function App() {
  const [profileImage, setProfileImage] = useState(null);
  //profile name and id
  const tokens = useSelector((state) => state.auth.tokens);

  const credentialData = decodeToken(tokens.access);

  const [credentials, setCredentials] = useState({
    username: credentialData.username,
    email: credentialData.email,
  });


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const testStyle = {
    flex: "0.97",
    fontSize: "1.5rem",
    fontFamily: "Arial, sans-serif",
    color: "#000000",
  };

  return (
    <div className="profile-app-container">
      <div className="profile-picture">
        <label htmlFor="imageUpload">
          <img src={profileImage || "/img/defaultIcon.png"} alt="Profile" />
        </label>
        <input
          type="file"
          id="imageUpload"
          accept="image/*"
          onChange={handleImageUpload}
        />
        <a style={testStyle}>{credentialData.username}</a>
      </div>
    </div>
  );
}
