import React, { useState, useRef, useEffect } from "react";
import { TextField, Button, Select, MenuItem, Modal, Paper, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { redirect, useNavigate } from "react-router-dom";
import axios from "axios";

export default function App() {
  const [passcode, setPasscode] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
  });
  const [passcodeToken, setPasscodeToken] = useState("");
  useEffect(() => {
    //console.log("Page is loaded");
    postData();
  }, []);
  const theme = useTheme();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [openModal, setOpenModal] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState(0);

  const inputRefs = {
    first: useRef(null),
    second: useRef(null),
    third: useRef(null),
    fourth: useRef(null),
    fifth: useRef(null),
    sixth: useRef(null),
  };

  const handleChange = (e, fieldName) => {
    // credentials[e.target.name]=e.target.value;
    // setCredentials({...credentials});
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "").substr(0, 1);
    setPasscode((prevPasscode) => ({
      ...prevPasscode,
      [fieldName]: numericValue,
    }));
    if (numericValue !== "") {
      focusNextInput(fieldName);
    }
  };

  const focusNextInput = (currentFieldName) => {
    const fieldNames = ["first", "second", "third", "fourth", "fifth", "sixth"];
    const currentIndex = fieldNames.indexOf(currentFieldName);
    if (currentIndex !== -1 && currentIndex < fieldNames.length - 1) {
      const nextFieldName = fieldNames[currentIndex + 1];
      inputRefs[nextFieldName].current.focus();
    }
  };

  const login = () => {
    const enteredCode = Object.values(passcode).join("");
    //const emailVerficationCode = "123456";
    if (enteredCode === passcodeToken) {
      navigate("/");
    } else {
      setError("Incorrect verification code, try again.");
      setFailedAttempts((prevAttempts) => prevAttempts + 1); // Increment failed attempts
      if (failedAttempts === 4) {
        setOpenModal(true); // Open the modal automatically after 5 failed attempts
      }
    }
  };

  const handleResendClick = () => {
    postData();
    setPasscode({
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      sixth: "",
    });
    setResendDisabled(true);
    let timer = 60;
    const interval = setInterval(() => {
      timer -= 1;
      setCountdown(timer);
      if (timer === 0) {
        clearInterval(interval);
        setResendDisabled(false);
      }
    }, 1000);
    setOpenModal(false);
    setFailedAttempts(0);
  };

  const handleOpenModal = () => {
    if (failedAttempts === 5) {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFailedAttempts(0);
  };

  async function postData() {
    try {
      const response = await axios.post(
        "https://app.otbenglishpd.com/send-otp/",
        {
          email: JSON.parse(localStorage.getItem("email")).email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //console.log(response.data.otp);
      setPasscodeToken(response.data.otp);
      // Handle the response data here
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  }

  return (
    <div className="main">
      <img src="/img/logo_OTB_horizontal.jpg" className="logo-center"></img>
      <Button onClick={handleOpenModal}></Button>
            <Modal open={openModal} onClose={handleCloseModal}>
              <Box className="modal">
                <p>Too many incorrect attempts</p>
                <p></p>
                <div>
                 <a onClick={handleResendClick}>Send again</a>
              </div>
              </Box>
            </Modal>
      <div className="container">
        <form>
          <p className="form-title">Verify your email</p>
          <p>
            Please enter the code sent to{" "}
            {JSON.parse(localStorage.getItem("email")).email}
          </p>
          <div className="verification-code-group">
            <TextField
              name="first"
              value={passcode.first}
              onChange={(e) => handleChange(e, "first")}
              inputRef={inputRefs.first}
              required
              className="verification-code"
              inputMode="numeric"
              InputProps={{
                style: {
                  textAlign: "center",
                  width: "2.5em",
                },
              }}
            />
            <TextField
              name="second"
              value={passcode.second}
              onChange={(e) => handleChange(e, "second")}
              inputRef={inputRefs.second}
              required
              className="verification-code"
              inputMode="numeric"
              InputProps={{
                style: {
                  textAlign: "center",
                  width: "2.5em",
                },
              }}
            />
            <TextField
              name="third"
              value={passcode.third}
              onChange={(e) => handleChange(e, "third")}
              inputRef={inputRefs.third}
              required
              className="verification-code"
              inputMode="numeric"
              InputProps={{
                style: {
                  textAlign: "center",
                  width: "2.5em",
                },
              }}
            />
            <TextField
              name="fourth"
              value={passcode.fourth}
              onChange={(e) => handleChange(e, "fourth")}
              inputRef={inputRefs.fourth}
              required
              className="verification-code"
              inputMode="numeric"
              InputProps={{
                style: {
                  textAlign: "center",
                  width: "2.5em",
                },
              }}
            />
            <TextField
              name="fifth"
              value={passcode.fifth}
              onChange={(e) => handleChange(e, "fifth")}
              inputRef={inputRefs.fifth}
              required
              className="verification-code"
              inputMode="numeric"
              InputProps={{
                style: {
                  textAlign: "center",
                  width: "2.5em",
                },
              }}
            />
            <TextField
              name="sixth"
              value={passcode.sixth}
              onChange={(e) => handleChange(e, "sixth")}
              inputRef={inputRefs.sixth}
              required
              className="verification-code"
              inputMode="numeric"
              InputProps={{
                style: {
                  textAlign: "center",
                  width: "2.5em",
                },
              }}
            />
          </div>
          <p></p>
          {error && <p style={{ color: "red" }}>{error}</p>}
          {!resendDisabled ? (
            <a onClick={handleResendClick}>Didn't receive a code? Send again</a>
          ) : (
            <p>Resend in {countdown} seconds</p>
          )}
          <p></p>
          <Button
            variant="contained"
            color={theme.primary}
            className="block-button"
            onClick={() => login()}
          >
            Verify
          </Button>
        </form>
      </div>
    </div>
  );
}
