import React, { useState } from "react";
import { TextField, Button, Checkbox, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './form.css';

export default function App() {
  const [credentials, setCredentials] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    passwordConfirm: "",
  });
  const [nameValid, setNameValid] = useState(false);
  const [nameLengthValid, setNameLengthValid] = useState(false);
  const [nameAlphabatic, setNameAlphabatic] = useState(false);
  const [nameEmpty, setNameEmpty] = useState(true);

  const [usernameValid, setUsernameValid] = useState(false);
  const [usernameLengthValid, setUsernameLengthValid] = useState(false);
  const [usernameAlphanumeric, setUsernameAlphanumeric] = useState(false);
  const [usernameEmpty, setUsernameEmpty] = useState(true);

  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordLengthValid, setPasswordLengthValid] = useState(false);
  const [passwordEmpty, setPasswordEmpty] = useState(true);

  const [emailValid, setEmailValid] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState(true);

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);

  const nameRegex = /^[A-Za-z ]+$/;
  const usernameRegex = /^[A-Za-z\d]{8,20}$/;
  const passwordRegex = /^[A-Za-z0-9!@#$%^&*()_+]{8,20}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const emptyRegex = /^$/;

  const theme = useTheme();
  const navigate = useNavigate();

  const handleChange = (e, text, empty) => {
    const { name, value } = e.target;

    const regexPattern = text;
    const regexAlphaNumeric = /^[a-zA-Z0-9]+$/;
    const regexAlphabatic = /^[a-zA-Z ]+$/;
    const regexLength = /^.{8,20}$/;
    const regexPatternEmpty = empty;
    const isValid = regexPattern.test(value);
    const isAlphanumeric = regexAlphaNumeric.test(value);
    const isAlphabatic = regexAlphabatic.test(value);
    const isLengthValid = regexLength.test(value);
    const isEmpty = regexPatternEmpty.test(value);

    credentials[e.target.name] = e.target.value;
    setCredentials({ ...credentials, [name]: value });

    if (text === usernameRegex) {
      setUsernameValid(isValid);
      setUsernameAlphanumeric(isAlphanumeric);
      setUsernameLengthValid(isLengthValid);
      setUsernameEmpty(isEmpty);
    } else if (text === passwordRegex) {
      setPasswordLengthValid(isLengthValid);
      setPasswordValid(isValid);
      setPasswordEmpty(isEmpty);
      passwordMatchHandel();
    } else if (text === emailRegex) {
      setEmailValid(isValid);
      setEmailEmpty(isEmpty);
    } else if (text === nameRegex) {
      setNameValid(isValid);
      setNameAlphabatic(isAlphabatic);
      setNameLengthValid(isLengthValid);
      setNameEmpty(isEmpty);
    }
  };

  const passwordMatchHandel = () => {
    if (credentials.password === credentials.passwordConfirm) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  };

  const handleSubmit = () => {
    if (
      nameValid &&
      usernameValid &&
      passwordValid &&
      emailValid &&
      passwordMatch &&
      termsChecked
    ) {
      fetchData();
    } else {
      //call popup modal
    }
  };

  const handleCheck = () => {
    setTermsChecked(!termsChecked);
  };

  async function fetchData() {
    try {
      const response = await axios.post(
        "https://app.otbenglishpd.com/users/",
        {
          name: credentials.name,
          username: credentials.username,
          password: credentials.password,
          passwordConfirm: credentials.passwordConfirm,
          email: credentials.email,
          first_name: "",
          last_name: "",
          is_staff: false,
          is_active: false,
          date_joined: new Date(),
          groups: [],
          user_permissions: [],
          last_login: null,
          is_superuser: false,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const email = { email: credentials.email };
      localStorage.setItem("email", JSON.stringify(email));
      if (response.status < 400) {
        navigate("/email");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div className="container">
      <form>
        <p className="form-title">Create an account</p>
        <TextField
          name="name"
          label="Name"
          value={credentials.name}
          onChange={(e) => handleChange(e, nameRegex, emptyRegex)}
          required
          className="block-button"
          error={!nameValid || nameEmpty}
        />
        <Stack>
          {!nameLengthValid && !nameEmpty && (
            <Typography color="red">• Must have 8-20 characters</Typography>
          )}
          {!nameAlphabatic && !nameEmpty && (
            <Typography color="red">• Must be Alphabatic</Typography>
          )}
        </Stack>
        <TextField
          name="username"
          label="Username"
          value={credentials.username}
          onChange={(e) => handleChange(e, usernameRegex, emptyRegex)}
          required
          className="block-button"
          error={!usernameValid || usernameEmpty}
        />
        <Stack>
          {!usernameLengthValid && !usernameEmpty && (
            <Typography color="red">• Must have 8-20 characters</Typography>
          )}
          {!usernameAlphanumeric && !usernameEmpty && (
            <Typography color="red">• Must be Alphanumeric</Typography>
          )}
        </Stack>
        <TextField
          name="email"
          label="Email"
          value={credentials.email}
          onChange={(e) => handleChange(e, emailRegex, emptyRegex)}
          required
          className="block-button"
          error={!emailValid || emailEmpty}
        />
        <Stack>
          {!emailValid && !emailEmpty && (
            <Typography color="red">• Must have valid Email (i.e @.)</Typography>
          )}
        </Stack>
        <TextField
          name="password"
          label="Password"
          type="password"
          value={credentials.password}
          onChange={(e) => handleChange(e, passwordRegex, emptyRegex)}
          required
          className="block-button"
          error={!passwordValid || passwordEmpty || !passwordMatch}
        />
        <Stack>
          {!passwordLengthValid && !passwordEmpty && (
            <Typography color="red">• Must have 8-20 characters</Typography>
          )}
          {!passwordValid && !passwordEmpty && (
            <Typography color="red">• Must be Alphanumeric</Typography>
          )}
          {!passwordValid && !passwordEmpty && (
            <Typography color="red">• Optional: (!@#$%^&*())</Typography>
          )}
        </Stack>
        <TextField
          name="passwordConfirm"
          label="Password"
          type="password"
          value={credentials.passwordConfirm}
          onChange={(e) => handleChange(e, passwordRegex, emptyRegex)}
          required
          className="block-button"
          error={!passwordMatch}
        />
        <Stack>
          {!passwordMatch && (
            <Typography color="red">• Passwords must be the same</Typography>
          )}
        </Stack>
        <Checkbox checked={termsChecked} onChange={handleCheck} /> Agree with{" "}
        <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>
        <p></p>
        <Button
          variant="contained"
          color={theme.primary}
          className="block-button"
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </form>
    </div>
  );
}
