import React, { useState, useEffect, useRef } from "react";
import { CardHeader, Stack,
  Avatar,Container,Box,Typography,Button,Modal,
  BottomNavigation,BottomNavigationAction,TextField,
  Paper, List,ListItemText,
  ListItem,ListItemAvatar,} from "@mui/material";
import axios from 'axios'; 
import OneFilter from '../filters/home_preview';
import HomeHeader from "./header";
import Units from "./units";
import Navbar from '../navbar/app';
import FolderIcon from '@mui/icons-material/Folder';
import { redirect, useNavigate } from "react-router-dom";



function Body(props) {
  const navigate = useNavigate();
  const [filters, setFilters] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [phrasesArray, setPhrasesArray] = useState([]);
  const [searchFilter, setSearchFilter] = useState(filters)
  const [searchPhrases, setSearchPhrases] = useState(phrasesArray)


  useEffect(()=>{
    const loadSearch = () =>{
      let search = props.search.trim()
      let newFilters = filters.filter((filter)=> filter.toLowerCase().includes(search.toLowerCase()))
      let newPhrases = phrasesArray.filter((phrase)=> phrase.toLowerCase().includes(search.toLowerCase()))
      setSearchFilter(newFilters.slice(0, 3))
      setSearchPhrases(newPhrases)
    }
    loadSearch()
  }, [props.search])

  const setPhrases = async () => {
    try {
      const phraseApiResponse = await axios.get(`https://app.otbenglishpd.com/filter/phrases/`);
      const phraseArr = phraseApiResponse.data;
      const phrasesArray = phraseArr.map(phrase=> {
        return phrase.phrase // Return empty string if not found
      });
      // console.log(phrasesArray);
      
      setPhrasesArray(phrasesArray);
      setSearchPhrases(phrasesArray)
    } catch (error) {
      console.log(error);
    }
  };
  
  const getFilters = async () => {
    try {
      const filtersApiResponse = await axios.get(`https://app.otbenglishpd.com/filter/filters/`);
      const filtersArray = filtersApiResponse.data;
      
      // console.log(filtersArray);
      // console.log(filtersArray.length);
      setFilters(filtersArray.slice(0, 3));
      setSearchFilter(filtersArray.slice(0, 3))
      
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    setPhrases();
    getFilters();
  },[]);
  useEffect(() => {
    setPhrases()
    getFilters();
  },[props.changed]);

  const handleAddFilter = () => {
    if (filters.length < 3) {
      setModalOpen(true);
    }
  };

  async function postData() {
    try {
      const response = await axios.post(
        "https://app.otbenglishpd.com/filter/filters/",
        {
          name: filterName,
          phrases: [],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      // Handle any errors here
      console.error("Error:", error);
    }
  }

  const handleAddFilterName = () => {
    if (filterName !== '') {
      const newFilter = {
        name: filterName,
        count: 0,
        phrase: "",
      };
      
      postData()
      setFilters([...filters, newFilter]);
      setModalOpen(false);
      setFilterName('');

    }
  };

  return (
    
      <Container className='home-filter'>
        <Stack direction="row" spacing={21} marginTop="5%">

          <Typography variant="h6">
              Filters
          </Typography>
          <Button variant="outlined" href="#outlined-buttons" onClick={()=>navigate('/filter')}>
              View All
          </Button>
        </Stack>
        {searchFilter.map((filter, index) => (
          <OneFilter key={index} name={filter.name} count={filter.count} phrase={filter.phrase} />
        ))}
        {searchFilter.length < 3 && (
          <Button 
            className="add-filter block-button" 
            onClick={handleAddFilter}
            variant="outlined"
          >
            + Add Filter
          </Button>
        )}
        <Modal
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="add-filter-modal"
        >
          <div className="modal">
            <h3>Enter Filter Name</h3>
            <TextField
              type="text"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value.trim())}
            />
            <Button onClick={handleAddFilterName} variant="outlined">
              ADD
            </Button>
          </div>
        </Modal>
      
        <Stack direction="row" spacing={10} marginTop={"5%"}>
          <Typography variant="h6" gutterBottom>
            Recent Phrases
          </Typography>
          <Button variant="outlined" href="#outlined-buttons" onClick={()=>navigate('/phrase')}>
            View All
          </Button>
        </Stack>
        <List style={{height:"140px",maxHeight:"200px",overflow:'auto'}}>
          {searchPhrases.map((phrase, index) => {

              return (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={phrase}
                    // secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
              )
            })}
                
        </List>
        <Button 
            className="add-phrase block-button" 
            onClick={()=>navigate('/createphrase/add')}
            variant="outlined">
              + Add Phrase
        </Button>
      </Container>
  );
}

export default function App(){
  const [search, setSearch] = useState('')
  const [changed, setChanged] = useState(false)
  return(
    <Container>
      <HomeHeader setSearch = {setSearch}/>
      <Body search = {search} changed={changed}/>
      <Navbar changed={changed} setChanged={setChanged}></Navbar>
    </Container>
  )
}