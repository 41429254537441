import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Modal,
  Box,
  Stack,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

function Header(props) {
  const { filterid } = useParams();

  const navigate = useNavigate();
  const [editFilterOpen, setEditFilterOpen] = React.useState(false);

  const [newFilterName, setNewFilterName] = useState("");
  const [filter, setFilter] = useState(undefined);

  const [lengthPhraseArray, setLengthPhraseArray] = useState(0);


  useEffect(() => {
    const getFilters = async (filterid) => {
      try {
        const filtersApiResponse = await axios.get(`https://app.otbenglishpd.com/filter/filters/`+filterid+"/");
        const filterData = filtersApiResponse.data
        setFilter(filterData)
        setLengthPhraseArray(filterData.phrases.length);
      } catch (error) {
        console.log(error);
      }
    };

    getFilters(filterid);
  },[]);

  const handleFilterEditClose = () => {
    setEditFilterOpen(false);
  };

  const handleFilterEditOpen = () => {
    setEditFilterOpen(true);
  };

  const handleEditFilterName = () => {
    //TODO edit filter name
    navigate(`/filter/${encodeURIComponent(newFilterName)}`);
    handleFilterEditClose();
  };

  const customStyles = {
    width: "120px", // Adjust the width as needed
  };
  const customSelectStyles = {
    fontSize: "12px", // Adjust the font size for the select input
  };
  return (
    <header className="header-general">
      <div className="filters-header-container">
        <Button
          variant="outlined"
          onClick={() => navigate(`/filter`)}
          style={{
            marginBottom: "10px",
            padding: "2px",
            fontSize: "14px",
            border: "2px solid", // Adjust the border thickness as needed
            textTransform: "none", // Keeps text case-sensitive
            width: "10px",
            fontWeight: "550", // Make the text lines thicker
            borderRadius: "10px",
          }}
        >
          Back
        </Button>
        <div className="container-space-between">
          <Typography variant="h5">#{filter?filter.name:""}</Typography>
          <Button
            onClick={handleFilterEditOpen}
            style={{ marginRight: "20px" }}
          >
            <EditIcon style={{ fontSize: "2em", color: "black" }} />
          </Button>
        </div>
        <Typography variant="body1">{lengthPhraseArray} Phrases</Typography>
      </div>
      <Modal
        open={editFilterOpen}
        onClose={handleFilterEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal">
          <Stack spacing={3}>
            <a>Edit Filter Name</a>
            <TextField
              id="standard-password-input"
              label="Filter Name"
              value={newFilterName}
              onChange={(e) => setNewFilterName(e.target.value)}
            />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handleFilterEditClose} variant="outlined">
                Cancel
              </Button>
              <Button variant="contained" onClick={handleEditFilterName}>
                Submit
              </Button>
            </div>
          </Stack>
        </Box>
      </Modal>
    </header>
  );
}
const mapStateToProps = (state) => {
  return {
    components: state.reducer.components,
  };
};


export default connect(mapStateToProps)(Header);
