import Header from './header';
import Body from './body';
import Navbar from '../navbar/app';

export default function App(){
    return(
        <div>
            <Header/>
            <Body/>
            <Navbar/>
        </div>
    )
}