import { BrowserRouter, Routes, Route, useSearchParams,Navigate } from "react-router-dom";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Login from "./component/login/app";
import SignUp from "./component/signup/app";
import Email from "./component/signup/email";
import Home from "./component/home/app";
import Profile from "./component/profile/app";
import Filters from "./component/filters/app";
import Phrases from "./component/phrases/app";
import Unit from "./component/unit/app";
import Level from "./component/unit/level/app";
import IndividualPhrase from "./component/phrases/individual/app";
import IndividualFilter from "./component/filters/individual/app";
import CreatePhrase from './component/CreatePhrase/app';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { isExpired } from "react-jwt";
import { useSelector } from "react-redux";

const theme = createTheme({
  palette: {
    primary: {
      main: "#005F8E",
    },
    secondary: {
      main: "#77676f",
    },
  },
});


const phraseid = 1; //retrieved from redux / other local storage
const filtername = "scheduling";
const level1 = "Associate 1";
const level2 = "Associate 2";
const level3 = "Manager 1";

export default function App() {
  const tokens = useSelector((state) => state.auth.tokens);

  //console.log('',localStorage.getItem("tokens"))

  //console.log('tokens', tokens)
  const isAuthenticated = tokens.access&&tokens.refresh;
  //console.log('isAuthenticated', isAuthenticated)
  return (
    <GoogleOAuthProvider clientId="1021944542704-e01thokhof39j28i2bmc43qclon86jja.apps.googleusercontent.com">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Routes>

            <Route exact path="/" element={isAuthenticated?<Home />:<Navigate to="/login" />} />
            <Route exact path="/login" element={isAuthenticated?<Navigate to="/home"/>:<Login/>} />
            <Route exact path="/home" element={isAuthenticated?<Home />:<Navigate to="/login" />} />
            <Route exact path="/signup" element={isAuthenticated?<Navigate to="/home"/>:<SignUp/>} />
            <Route exact path="/email" element={isAuthenticated?<Email />:<Navigate to="/login" />} />
            <Route exact path="/profile" element={isAuthenticated?<Profile />:<Navigate to="/login" />} />
            <Route exact path="/filter" element={isAuthenticated?<Filters />:<Navigate to="/login" />} />
            <Route exact path="/phrase" element={isAuthenticated?<Phrases />:<Navigate to="/login" />} />
            <Route exact path="/unit" element={isAuthenticated?<Unit />:<Navigate to="/login" />} />
            <Route exact path="/filter/:filterid" element={isAuthenticated?<IndividualFilter filtername={filtername}/>:<Navigate to="/login" />} />
            <Route exact path="/phrase/:phraseid" element={isAuthenticated?<IndividualPhrase phraseid={phraseid}/>:<Navigate to="/login" />} />
            <Route exact path='/unit/Associate1' element={isAuthenticated?<Level level={level1}/>:<Navigate to="/login" />} />
            <Route exact path='/unit/Associate2' element={isAuthenticated?<Level level={level2}/>:<Navigate to="/login" />} />
            <Route exact path='/unit/Manager1' element={isAuthenticated?<Level level={level3}/>:<Navigate to="/login" />} />
            <Route exact path='/createphrase/:update' element={isAuthenticated?<CreatePhrase/>:<Navigate to="/login" />}/>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}
