import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { phrases } from '../body';

import { useSelector } from "react-redux";

export default function Header() {
    const { phraseid } = useParams();
    const [selectedPhrase, setSelectedPhrase] = useState(null);

    useEffect(() => {
        const index = parseInt(phraseid, 10) - 1;
        if (index >= 0 && index < phrases.length) {
            setSelectedPhrase(phrases[index][0]);
        } else {
            setSelectedPhrase('Invalid Phrase ID');
        }
    }, [phraseid]);
    const updatePhrase = useSelector((state) => state.reducer.updatePhrase);
    

    return (
        <div className='phrase-header'>
            <h1>
            {updatePhrase?updatePhrase.phrase:""}
                <Link to="/createphrase/add" className="add-phrase-link">
                <img
                    src="/img/edit.png"
                    className="edit_phrase"
                    alt="OTB edit"
                />
                </Link>
            </h1>
        </div>
    );
}