import React, { useState } from 'react';
import { Box, BottomNavigation, BottomNavigationAction, Popover, Button, Typography, Modal, TextField} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faFilter, faPlus, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

export default function App(props){
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterName, setFilterName] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function postData() {
        try {
          const response = await axios.post(
            "https://app.otbenglishpd.com/filter/filters/",
            {
              name: filterName,
              phrases: [],
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          // Handle any errors here
          console.error("Error:", error);
        }
      }

    const handleAddFilterName = () => {
        if (filterName !== '') {
          const newFilter = {
            name: filterName,
            count: 0,
            phrase: "",
          };
          
          postData()
          setModalOpen(false);
          setFilterName('');
    
        }
        if (props.changed !== null && props.changed !== undefined) {
            props.setChanged(!props.changed)
        }
        handleClose()
      };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Box>
            <BottomNavigation className="navbar" showLabels>
                <BottomNavigationAction
                    className="navitem"
                    label="Home"
                    onClick={() => navigate('/home')}
                    icon={<FontAwesomeIcon icon={faHouse} />}
                />
                <BottomNavigationAction
                    className="navitem"
                    label="Filters"
                    onClick={() => navigate('/filter')}
                    icon={<FontAwesomeIcon icon={faFilter} />}
                />
                <BottomNavigationAction
                    className="navitem"
                    label="Add"
                    onClick={handleClick}
                    icon={<FontAwesomeIcon icon={faPlus} />}
                />
                <BottomNavigationAction
                    className="navitem"
                    label="Phrases"
                    onClick={() => navigate('/phrase')}
                    icon={<FontAwesomeIcon icon={faClockRotateLeft} />}
                />
                <BottomNavigationAction
                    className="navitem"
                    label="Units"
                    onClick={() => navigate('/unit')}
                    icon={<FontAwesomeIcon icon={faClockRotateLeft} />}
                />
            </BottomNavigation>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2} textAlign="center">
                    <Button onClick={() => navigate('/createphrase/add')}>Phrase</Button>
                    <Modal
                        open={isModalOpen}
                        onClose={() => setModalOpen(false)}
                        aria-labelledby="add-filter-modal"
                        >
                        <div className="modal">
                            <h3>Enter Filter Name</h3>
                            <TextField
                            type="text"
                            value={filterName}
                            onChange={(e) => setFilterName(e.target.value.trim())}
                            />
                            <Button onClick={handleAddFilterName} variant="outlined">
                            ADD
                            </Button>
                        </div>
                    </Modal>
                    <Button onClick={() => setModalOpen(true)}>Filter</Button>
                </Box>
            </Popover>
        </Box>
    );
};